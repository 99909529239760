export default {
  siteTitle: 'Get bonus',
  blogerName: 'MK ZED',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://youtube.com/@MKZedBet?si=pogFOhqr68rU7Evv'
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@MKZinSlots?si=UrXj7Z35ACXiGhDp'
    },
    {
      name: 'telegram',
      url: 'https://t.me/MKZin777'
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/c5d703acb',
      gameTitle: 'Book of Kemet',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c92a6b79c',
      gameTitle: 'IZZI ART',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/ce062f0b1',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: '1go',
      url: 'https://1go-irrs01.com/c4d81cc13',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c619f9ee4',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs12.com/c2bf6b7b7',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>MKZINHO</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'MKZINHO',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
